<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">
        {{ $t("menu.clinic") }}
        <!-- 도움말 -->
        <HelpMsg :msgType="helpMsg" />
      </v-card-text>
    </v-col>
    <v-col cols="12" v-if="isHospitalOne === 'N'">
      <SearchTopUIComp
        :placeholder="$t('common.component.searchTop.searchInputWord')"
        :propsSearchWorld="reqData.searchVal"
        @changeText="changeText"
        @changeSearchWordComplete="changeSearchWordComplete"
      />
    </v-col>
    <v-col cols="12" v-if="isHospitalOne === 'N'">
      <TopTable
        :btnLabel="$t('common.button.new')"
        :btnIsShow="true"
        :IsSwitchShow="true"
        :xlsxBtnIsShow="false"
        :switchLabel="switchLabel"
        :data="clinicData"
        @clickXlsxBtn="xlsxSave"
        @clickEditorBtn="clickNewBtn"
        @clickReload="clickReload"
        @changeSwitch="changeSwitch"
      />
    </v-col>
    <v-col cols="12" v-if="isHospitalOne === 'N'">
      <ClinicPhotoCard
        :data="clinicData"
        @clickBasic="clickBasic"
        @clickDetail="clickDetail"
        @clickProduct="clickProduct"
        @clickBookingSetting="clickBookingSetting"
      />

      <!-- <DataTable
        ref="DataTable"
        :loading="loading"
        :loadingText="loadingText"
        :tableKey="'rowSeq'"
        :perPage="10000"
        :footerHide="true"
        :singleSelect="true"
        :showSelect="false"
        :columns="clinicInfoColumns"
        :inputData="clinicData"
        @clickRow="clickRow"
      /> -->
    </v-col>

    <!-- basicDrawer -->
    <v-navigation-drawer
      v-model="basicDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      :width="basicDrawerWidth"
    >
      <basic-drawer ref="refBasicDrawer" @closeDrawer="basicDrawer = false">
        <template v-slot:userDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="
              basicDrawer = !basicDrawer;
              onClose();
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </basic-drawer>
    </v-navigation-drawer>

    <!-- productDrawer -->
    <!-- <v-navigation-drawer
      v-model="productDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      :width="productDrawerWidth"
    >
      <product-drawer
        ref="refProductDrawer"
        @closeDrawer="productDrawer = false"
      >
        <template v-slot:userDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="
              productDrawer = !productDrawer;
              onClose();
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </product-drawer>
    </v-navigation-drawer> -->

    <!-- add / edit -->
    <DialogClinicAdd ref="refClinicAdd" @complete="addComplete" />

    <!-- product -->
    <!-- <DialogClinicProductAdd ref="refDialogClinicProductAdd" /> -->

    <!-- 예약일정 셋팅 -->
    <DialogClinicBookingSetting
      ref="refDialogClinicBookingSetting"
      @complete="addComplete"
    />
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SearchTopUIComp from "@/components/commonV2/ui/SearchTopUIComp.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import TopTable from "@/components/commonV2/ui/TopTable.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogClinicAdd from "@/components/commonV2/Dialog/DialogClinicAdd.vue";
import DialogClinicBookingSetting from "@/components/commonV2/Dialog/DialogClinicBookingSetting.vue";
// import DialogClinicProductAdd from "@/components/commonV2/Dialog/DialogClinicProductAdd.vue";
import HelpMsg from "@/components/commonV2/helpMsg/HelpMsg.vue";
import ClinicPhotoCard from "./ClinicPhotoCard.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    BasicDrawer: () => import("./basicTabs/_basicTabs.vue"),
    ProductDrawer: () => import("./tabs/_Tabs.vue"),
    SearchTopUIComp,
    TopTable,
    DataTable,
    SelectBoxClinic,
    DialogClinicAdd,
    // DialogClinicProductAdd,
    HelpMsg,
    ClinicPhotoCard,
    DialogClinicBookingSetting,
  },
  data() {
    return {
      loading: false,
      loadingText: "Loading list...",
      basicDrawer: false,
      basicDrawerWidth: "90%",
      productDrawer: false,
      productDrawerWidth: "90%",
      // viewPhotoMode: "N",
      clinicData: [],
      // clinicInfoColumns: formatService.clinicInfoTableColumnsV1(),
      isHospitalOne: "N",
      switchLabel: this.$t("common.activeLabel"),
      propsDate: [this.$moment().subtract(30, "year"), this.$moment()],
      reqData: modelService.reqDataModel(),
      helpMsg: this.$t("helpMsg.clinic"),
      isDev: process.env.NODE_ENV === "development",
    };
  },
  mounted() {
    this.reqData.endpoint = "hospital";
    if (this.meInfo && this.meInfo.userType !== "super") {
      this.isHospitalOne = "Y";
      this.reqData.hospital_id = this.meInfo.hospital_id;
      this.reqData.endpoint = "hospitalOne";
    }
    this.getData();
  },
  methods: {
    /**** 검색어 ***/
    changeSearchWordComplete(e) {
      this.reqData.searchVal = e;
      this.getData();
    },
    changeText(e) {
      this.reqData.searchVal = e;
    },
    /**** 중간 ***/
    changeSwitch(e) {
      this.reqData.activated = e.length > 0 ? true : false;
      this.getData();
    },
    xlsxSave() {
      this.$helper.xlsxDownload(this.xlsxData, "sheet1", "ClinicList");
    },
    clickNewBtn() {
      let clinicOneData = modelService.hospitalModel();
      clinicOneData.hospital_id = this.reqData.hospital_id;
      this.$refs.refClinicAdd.Open(clinicOneData);
    },
    clickReload() {
      this.reqData.searchVal = "";
      this.selectedBtn = "all"; // 초기화할때 버튼과 date 값을 초기화 해야함
      this.propsDate = [this.$moment().subtract(30, "year"), this.$moment()];
      this.getData();
    },
    clickBasic(e) {
      this.$refs.refClinicAdd.Open(e);
    },
    clickDetail(e) {
      this.basicDrawer = !this.basicDrawer;
      setTimeout(() => {
        this.$refs.refBasicDrawer._updateData(e);
      }, 300);
    },
    clickProduct(e) {
      this.productDrawer = !this.productDrawer;
      setTimeout(() => {
        this.$refs.refProductDrawer._updateData(e);
      }, 300);
    },
    clickBookingSetting(e) {
      this.$refs.refDialogClinicBookingSetting.Open(e);
    },
    /**** 데이터읽기 ***/
    async getData() {
      this.list = [];
      const $h = this.$helper;
      this.reqData.fromDay = $h.changeDateToString(this.propsDate[0], false);
      this.reqData.toDay = $h.changeDateToString(this.propsDate[1], false);
      await this.getDataQuery(this.reqData).then((resP) => {
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            updDateLabel: $h.changeDateToStringForDp(item.updDate),
            useYNLabel: $h.memberTypeLbl(item.activated),
            signupPathLabel: $h.signupPathLabel(item.signupPath),
            isPhoto: $h.isPhotoChecker(item.hospitalPhotoImg),
            dpDateLbl: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            cardTitle: `${$h.definedStringI18($h.makeStringToArray(item.hospitalName, "@@"), 'kr')}(${item.cell})`, 
            cardSubTitle: "",
            //cardImg: "",
            productTypesLbl: this.makeProductName(item)
          }));
        }
        console.log(res);
        this.clinicData = res;
        if (this.isHospitalOne === "Y") {
          setTimeout(() => {
            this.$refs.refClinicAdd.Open(this.clinicData);
          }, 500);
        }
      });
    },
    makeProductName(item) {
      console.log('item', item.productTypes);
      const $h = this.$helper;
      item.productTypes.forEach(element => {
        element.productNameKr = $h.definedStringI18($h.makeStringToArray(element.productName, "@@"), 'kr')
      });
      return item;
    },
    /**** 저장/수정/삭제 후 처리 ***/
    addComplete(e) {
      this.getData();
    },
    onClose() {
      console.log("onClose");
      // this.$refs.refPatientDrawer.onClose();
      // setTimeout(() => this.patientDrawer = false, 3000);
    },
    ...mapActions(["getDataQuery"]),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>
<style lang="">
</style>